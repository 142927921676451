.bg-image{
  /* z-index: -1 !important; */
}
.nav-link{
  /* color: #fff !important; */
}
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
.image-gallery-image{
  border-radius: 10px;
}
.registerbutton2{
  background-color: #3D72C1;
  color: #fff;
  border-radius: 30;
  /* border-radius: 10px; */
  font-size: 15px;
  text-align: center;
  padding: 5px !important;
}
.registerbutton3{
  background-color: #3D72C1;
  color: #fff;
  /* border-radius: 10px; */
  font-size: 15px;
  text-align: center;
  padding: 5px !important;
}
#navbar{
  padding-top: 0px !important;
}
.navbar{

background-color: #fff;

}
/* #agenda {
    scroll-margin: 60px;
  } */

  /* #video {
    scroll-margin: 160px;
  } */

.youtube-video {
    aspect-ratio: 16 / 9;
    width: 100%;
  }
.loginbox{
    /* position: absolute !important;
    right:20 !important; */
    width: 25%;
    top: 20%;
    right: 7%;
    position: absolute;
}
.registerbox{
    /* position: absolute !important;
    right:20 !important; */
    width: 25%;
    top: 20%;
    right: 7%;
    position: absolute;
}
img.demo {
    filter: brightness(120%);
    filter: contrast(120%);
    filter: drop-shadow(10px 10px 10px black);
 }